import { useCallback } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const api = buildService('/companies/{id}/company_contracts/{id}/company_contract_attachments', {
  only: ['delete']
}, {
  only: ['postFormData']
});
const useUploadCompanyContractFile = (companyId, companyContractId, onFileUpload) => {
  return useErrorHandling(useCallback(async formData => {
    const updatedCompanyContract = await api.postFormData(companyId, companyContractId, formData);
    onFileUpload(updatedCompanyContract);
  }, [companyContractId, companyId, onFileUpload]));
};
const useDeleteCompanyContractFile = (companyId, companyContractId, attachmentId) => {
  return useErrorHandling(useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this company contract file?')) {
      return await api.delete(companyId, companyContractId, attachmentId);
    }
  }, [attachmentId, companyContractId, companyId]));
};
const companyContractAttachmentsService = {
  useUploadCompanyContractFile,
  useDeleteCompanyContractFile
};
export default companyContractAttachmentsService;