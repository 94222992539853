import { createPagesAbilitiesProxy } from '@rd-web-markets/shared/dist/util/userAbilities';
import { useEffect, useState } from 'react';
function getUserClaimGroupAbilities(user, claimGroupId) {
  // by default using useParams will return string, so just in case
  claimGroupId = parseInt(claimGroupId);
  if (!user || !claimGroupId) {
    return createPagesAbilitiesProxy({
      pages: {}
    });
  }
  const abilities = {
    pages: {
      ClaimGroupEditGeneralPage: {
        canEditConsultants: user.account_type === 'admin' // for now
      }
    }
  };
  const proxy = createPagesAbilitiesProxy(abilities);
  return proxy;
}
export function useGetUserClaimGroupAbilities(user, claimGroupId) {
  // it always initially returns this state, so we need to build it asap
  const [userClaimGroupAbilities, setUserClaimGroupAbilities] = useState(getUserClaimGroupAbilities(user, claimGroupId));
  useEffect(() => {
    const claimGroupAbilities = getUserClaimGroupAbilities(user, claimGroupId);
    setUserClaimGroupAbilities(claimGroupAbilities);
  }, [user, claimGroupId]);
  return userClaimGroupAbilities;
}